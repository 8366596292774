<script lang="ts">
  import type {QBus} from "@otto-ec/event-q-bus";
  import {FindClass} from "../../constants/squirrel";
  import {SqrlGlobal} from "../../util/SqrlGlobal";
  import {tracking} from "../../tracking/Tracking";
  import {SanHeader} from "../../events/sanHeader";
  import {eventQBus} from "@otto-ec/event-q-bus";
  import {SQRL_EVENT} from "../../events/sqrlEvents";
  import {submitStore} from "../../store/SubmitStore";

  const bus: QBus = eventQBus();

  function toggleSearchField(event: Event) {
    if (event instanceof KeyboardEvent && event.key !== "Enter") {
      return;
    }
    toggle();
  }

  function toggle(force?: boolean) {
    const findHeader: HTMLDivElement = document.querySelector(`.${FindClass.HEADER}`) as HTMLDivElement;
    const wasVisible: boolean = findHeader.classList.contains(FindClass.HEADER_VISIBILITY) || false;
    const san_Header: SanHeader = wasVisible ? SanHeader.CLOSE : SanHeader.OPEN;

    findHeader.classList.toggle(FindClass.HEADER_VISIBILITY, force);

    if (!wasVisible) {
      const searchField: HTMLInputElement = document.querySelector(
        ".js_squirrel_searchbar__input"
      ) as HTMLInputElement;
      searchField?.focus();
      SqrlGlobal.scrollTo(searchField);
    }

    tracking.trackSubmitEvent({san_Header});
  }

  bus.on(SQRL_EVENT.SEARCH_HIDE, () => toggle(false));
  bus.on(SQRL_EVENT.SEARCH_SHOW, (context) => {
    submitStore.$breadcrumbSearch = context?.breadcrumb || false;
    toggle(true);
  });

</script>

<div class="squirrel_searchIcon js_squirrel_searchIcon"
     data-testid="squirrel-search-icon"
     on:click={toggleSearchField}
     on:keydown={toggleSearchField}
     role="button">
    <span class="p_icons squirrel_searchIcon__icon">»</span>
    <span class="squirrel_searchIcon__label">Suche</span>
</div>

<style lang="scss">
  @use "~@otto-ec/otto-components-utils/scss/_mixins/breakpoint";
  @use "@otto-ec/design-tokens/tokens";

  .squirrel_searchIcon {
    cursor: pointer;
    color: tokens.$oc-base-color-gray-400;
    text-align: center;

    &__icon {
      line-height: tokens.$oc-base-font-line-height-175;
      font-size: tokens.$oc-base-font-size-150;
      display: block;

    }

    &__label {
      line-height: tokens.$oc-base-font-line-height-75;
      font-size: tokens.$oc-base-font-size-62;

      display: block;

      @at-root .find_header--reduced#{&} {
        display: none;
      }

      @include breakpoint.breakpoint-from-l($to: false) {
        display: block !important;
      }
    }
  }
</style>
