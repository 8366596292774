export enum SqrlClass {
  ACTIVE = "squirrel_active",
}

export enum SqrlQaId {
  SUGGEST_ITEM = "search-suggest",
  SUGGEST_ROW = "search-suggest-text",
  MODIFIED_ARROW = "search-suggest-arrow",
  WRAPPER = "search-field-wrapper",
  SUBMIT_BUTTON = "search-field-submit",
  SEARCH_RESET = "search-field-reset",
  SEARCH_FIELD = "search-field",
  HISTORY_ITEM = "search-history",
  HISTORY_ITEM_ROW = "squirrel-suggest-text",
}

export enum SqrlTestId {
  LIST_ICON = "squirrel-list-icon",
  MODIFIED_ARROW = "search-suggest-arrow",
  SEARCH_RESET = "search-field-reset",
  SUBMIT_BUTTON = "search-field-submit",
  SEARCH_FIELD = "squirrel-searchfield",
  SUGGEST_LAYER = "squirrel-suggestlayer",
  TRENDING_QUERY_LIST = "squirrel-trendingQueries",
  SUGGESTION_LIST = "squirrel-suggestions",
  TEST = "squirrel-test",
  HISTORY_LIST = "squirrel-historyList",
  SEARCH_LAYER = "squirrel-searchlayer",
  HISTORY_EDIT_BUTTON = "squirrel-history-edit-button",
  ABORT_BUTTON = "squirrel-abort-button",
  SEARCH_ICON = "squirrel-search-icon",
}

export enum Role {
  BUTTON = "button",
  IMAGE = "img",
}

export enum FindClass {
  HEADER = "js_find_header",
  HEADER_VISIBILITY = "find_header--visibleSearchbar",
}

export enum FindQaId {
  SUBMIT_BUTTON = "ftfind-search-submit",
  SEARCH_RESET_SVG = "ftfind-search-reset",
}

export enum FindQa {
  WRAPPER = "ftfind-search-wrapper",
  SEARCH_FIELD = "ftfind-search-field",
}
