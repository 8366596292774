<script lang="ts">
  import type {Suggestion} from "../../generated";
  import type {SqrlListIconModel, SqrlListItemDetails, SqrlListItemModel} from "../../types/squirrel";
  import {createEventDispatcher, onDestroy} from "svelte";
  import {SqrlQaId, SqrlTestId} from "../../constants/squirrel";
  import {keywords} from "../../store/SuggestionStore";
  import {timeSuggest, timeSuggestRender} from "../../times/TimeFactory";
  import {indexStore} from "../../store/IndexStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import {isModified, value} from "../../store/SearchfieldStore";
  import {tlSuggestAdoptPosition} from "../../tracking/labels/SuggestAdoptPosition";
  import {tlSuggestAdoptTerm} from "../../tracking/labels/SuggestAdoptTerm";
  import {tracking} from "../../tracking/Tracking";
  import SqrlListItem from "../interactive/listItem/SqrlListItem.svelte";
  import {COLOR} from "../../constants/color";

  const unsubscribe = keywords.subscribe((value) => {
    indexStore.updateMaxIndex(value.length);
  });
  timeSuggest.mark();

  timeSuggestRender.measure();

  const dispatch = createEventDispatcher();
  const modifiedArrow: SqrlListIconModel = {
    icon: "search-enter",
    qaId: SqrlQaId.MODIFIED_ARROW,
    testId: SqrlTestId.MODIFIED_ARROW,
    style: {color: COLOR.RED_100},
    classNames: ["squirrel_searchSuggest__arrow", "js_squirrel_searchSuggest__arrow", "squirrel_searchSuggest__right-icon"]
  }

  function handleClick(event: CustomEvent<SqrlListItemDetails>) {
    timeSuggest.measure();
    submitStore.handleSubmit({term: event.detail.term}, event.detail.target, SUBMIT_TYPE.SUGGEST_ITEM);
  }

  /**
 *
 *
 *
 */
  function changeSearchTerm(event: CustomEvent<SqrlListItemDetails>) {
    if (event instanceof KeyboardEvent && event.key !== "Enter") {
      return;
    }
    $isModified = true;
    indexStore.reset();
    $value = `${event.detail.term} `;
    /*                                                                                                     */
    const inputElement: HTMLInputElement | null = document.querySelector(".js_squirrel_searchbar__input");
    inputElement?.focus();
    dispatch("sendRequest", {term: $value, cursorPosition: $value.length});
    tlSuggestAdoptPosition.value = event.detail.index + 1;
    tlSuggestAdoptTerm.value = $value;
    tracking.trackSubmitEvent({...tlSuggestAdoptPosition.data, ...tlSuggestAdoptTerm.data});
  }

  function createModel(suggestion: Suggestion, index: number): SqrlListItemModel {
    return {
      name: "searchSuggest",
      suggestion,
      index,
      leftIcon: {
        iconTarget: suggestion.iconTarget,
        icon: suggestion.icon
      },
      rightIcon: modifiedArrow
    }
  }

  onDestroy(() => {
    timeSuggest.measure();
    unsubscribe();
  })
</script>

<div class="squirrel_searchSuggestions" data-testid={SqrlTestId.SUGGESTION_LIST}>
    <ul class="squirrel_searchSuggestions__list squirrel_border_top">
        {#each $keywords as suggestion, index}
            <SqrlListItem
                params={createModel(suggestion, index)}
                on:clickItem={handleClick}
                on:clickLeftIcon={handleClick}
                on:clickRightIcon={changeSearchTerm}
            />
        {/each}
    </ul>
</div>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  .squirrel_searchSuggestions {
    background-color: tokens.$oc-base-color-white;
    padding: tokens.$oc-semantic-spacing-0;
    border-radius: tokens.$oc-semantic-border-radius-0 tokens.$oc-semantic-border-radius-0 tokens.$oc-semantic-border-radius-100 tokens.$oc-semantic-border-radius-100;

    .squirrel_border_top {
      border-top: tokens.$oc-semantic-border-width-400 solid tokens.$oc-base-color-gray-25;
    }
  }
</style>
