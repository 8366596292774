import { getElementById } from "../util/Utils";
import { any } from "../util/Reductions";

const ToggleNames = [
  "DUMMY",
  "HEUREKA_663_CLUSTER_REDUZIERUNG_FIRST_LEVEL",
  "HEUREKA_663_CLUSTER_REDUZIERUNG_FIRST_LEVEL_EXPERIMENT",
  "HEUREKA_663_CLUSTER_REDUZIERUNG_FIRST_LEVEL_TRACKING",
  "HEUREKA_1282_SMART_SORTING_NEXT_GENERATION_E3387",
  "HEUREKA_1537_CRISPY_FILTER_LISTS",
  "HEUREKA_1537_CRISPY_FILTER_LISTS_E3418",
  "HEUREKA_1367_REFACTOR_FACET_VALUE_TAGS",
  "HEUREKA_1561_FRIENDLY_SLIDERS",
] as const;

export type ToggleName = (typeof ToggleNames)[number];
export type ToggleStates = Record<ToggleName, boolean>;

let toggles: ToggleStates;

function asBoolean(value?: string): boolean {
  return "true" === value;
}

export function initToggles() {
  const data = getElementById("leafcutter_toggles")?.dataset;
  const state = data
    ? (toggleName: ToggleName) => asBoolean(data[toggleName] || data[toggleName.toLowerCase()])
    : () => false;
  toggles = ToggleNames.reduce((htmlToggles, toggleName) => {
    htmlToggles[toggleName] = state(toggleName);
    return htmlToggles;
  }, {} as Partial<ToggleStates>) as ToggleStates;
}

/**
 *
 */
export function updateToggle(toggle: ToggleName, state: boolean) {
  if (!toggles) {
    toggles = {} as ToggleStates;
  }
  toggles[toggle] = state;
}

export function isInactive(toggle: ToggleName) {
  return !isActive(toggle);
}

export function isActive(toggle: ToggleName) {
  return toggles[toggle];
}

export function isAnyActive(...toggles: ToggleName[]) {
  return toggles.map(isActive).reduce(any, false);
}
