<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { FacetValueGroup, FacetValueGroupOptions } from "./type.ts";
  import { Chip } from "../facet-value";

  interface Props extends FacetValueGroup, FacetValueGroupOptions {
    /**
 */
    bleed?: string;
  }

  let { values = [], title, item = chip }: Props = $props();
</script>

{#snippet chip(facetValue: FacetValue)}
  <Chip {...facetValue} />
{/snippet}

{#if values.length > 0}
  <div>
    {#if title}<h4>{title}</h4>{/if}
    <ul>
      {#each values as facetValue}
        <li>{@render item(facetValue)}</li>
      {/each}
    </ul>
  </div>
{/if}

<style lang="scss">
  h4 {
    margin-bottom: var(--oc-semantic-spacing-75);
    font: var(--oc-semantic-font-copy-75);
    text-transform: uppercase;
    color: var(--oc-semantic-color-text-secondary);
  }

  ul {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: var(--oc-semantic-spacing-100) var(--oc-semantic-spacing-50);

    /*                       */
    /*                                                                                                      */
    overflow-x: auto;
    scrollbar-width: none;
    margin: calc(-2 * var(--oc-semantic-focus-outline-offset) - 1px) calc(-1 * var(--bleed, 0));
    padding: calc(2 * var(--oc-semantic-focus-outline-offset) + 1px) var(--bleed, 0);

    li {
      flex-basis: max-content;
    }
  }
</style>
