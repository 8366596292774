import { eventQBus } from "../utils/EventQBus";
import { OnTileLoaded } from "../types/Tile";
import { hydrate } from "svelte";
import Price from "@otto-ec/reptile-components/src/lib/price/Price.svelte";
import BigPrice from "@otto-ec/reptile-components/src/lib/price/BigPrice.svelte";
import type { Price as Props } from "@otto-ec/reptile-components/src/lib/price/type";

export default function registerSvelteTile() {
  eventQBus.on("ftfind.tile.loaded", onTileLoaded);
}

function onTileLoaded(data: OnTileLoaded) {
  const EXPERIMENTS_ELEMENT_ID = "reptile-everglades-experiments";
  const experimentData = document.getElementById(EXPERIMENTS_ELEMENT_ID)?.dataset;
  if (!experimentData || !experimentData["e3431"]) {
    return;
  }
  const experimentVariation = experimentData["e3431"];
  if (experimentVariation === "SvelteV1") {
    hydratePriceContainer(Price, data.target);
  } else if (experimentVariation === "SvelteV2") {
    hydratePriceContainer(BigPrice, data.target);
  }
}

function hydratePriceContainer(component, tileTarget: HTMLElement) {
  const priceContainer: HTMLElement = tileTarget.getElementsByClassName("find_tile__priceContainer")[0] as HTMLElement;
  const priceData = priceContainer.dataset;
  const props: Partial<Props> = {
    retailPrice: priceData.retailPrice!,
    comparativePrice: priceData.comparativePrice!,
    suggestedRetailPrice: priceData.suggestedretailprice!,
    sale: JSON.parse(priceData.sale!),
    unitPrice: priceData.unitPrice === undefined ? undefined : JSON.parse(priceData.unitPrice),
    showUvp: JSON.parse(priceData.showUvp!),
    showTopPrice:
      JSON.parse(priceData.showTopPrice!) && document.querySelector(".reptile_searchResult--showTopPrice") !== null,
    dealHighlightText: priceData.dealHighlightText,
    hasSaleTags: JSON.parse(priceData.hasSaleTags!),
    discount: priceData.discount,
    installments:
      priceData.installmentsRate === undefined ||
      document.querySelector(".reptile_searchResult--showInstallment") === null
        ? undefined
        : {
            installmentsRate: priceData.installmentsRate!,
            numberOfMonthlyInstallments: priceData.installmentsNumber!,
          },
    showUVPTooltip: JSON.parse(priceData.showUvpTooltip!),
    discountBenefit: priceData.discountBenefitId
      ? {
          id: priceData.discountBenefitId!,
          displayValue: priceData.discountBenefitDisplayValue!,
        }
      : undefined,
  };
  hydrate(component, {
    target: priceContainer,
    props,
  });
}
