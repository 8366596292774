import {track} from "@otto-ec/nav_star-track/src/js/star-track";
import {calculateTopOffset} from "./utils";


export class MobileMenu extends HTMLElement {
    static customElement = "nav-mobile-menu";
    static observedAttributes = ["shift"];
    static visibleBemModifier = "nav_mobile-menu--visible"

    constructor() {
        super();
        this.setAttribute("shift", 0);
    }

    connectedCallback() {
        let shadow = this.shadowRoot ||this.attachShadow({mode: "open"});

        /*                                                  */
        shadow.innerHTML = `
        <div class="inner-frame" style="transition: transform .3s ease, left .3s ease; position: absolute; left: 0;">
            <slot />
        </div>
        `;
        track(this, "user-action/menu-open"); /*      */
    }

    shiftRight (n) {
        this.setAttribute("shift", parseInt(this.getAttribute("shift")) + n);
    }

    resetShift () {
        this.setAttribute("shift", 0);
    }

    toggleVisibility () {
        this.classList.toggle(MobileMenu.visibleBemModifier);
    }

    attributeChangedCallback (name, oldValue, newValue) {
        if (name === "shift" && oldValue !== newValue && this.shadowRoot) {
            const element = this.shadowRoot.querySelector(".inner-frame");
            const dx = parseInt(this.getAttribute("shift")) * (-100);
            element.style.transform = `translateX(${dx}vw)`;
        }
    }
}

export class MenuSheet extends HTMLElement {
    static customElement = "nav-menu-sheet";
    static visible = "nav_menu-sheet--visible";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.shadowRoot || this.attachShadow({mode: "open"});
        shadow.innerHTML = `<slot></slot>`;
        /*                                 */
        this.querySelectorAll('[data-next-sheet]').forEach(this.addNavigateDownListener);
    }


    ensureLoaded (showp) {
        if (!this.getAttribute("href") || this.loaded || !showp) {
            return Promise.resolve();
        } else {
            return fetch(this.getAttribute("href"))
                .then(response => response.text())
                .then(text => this.insertAdjacentHTML("beforeend", text))
                .then(() => {
                    this.loaded = true;

                    /*                                  */
                    this.querySelectorAll('a.nav_menu-link_js').forEach(this.addVisitMenuListener);
                    /*                                                */
                    this.querySelectorAll('[data-next-sheet]').forEach(this.addNavigateDownListener);

                    /*                                     */
                    this.addVisitMenuListener(this.querySelector('a.nav_title-link_js'));
                    this.addNavigateUpListener(this.querySelector('[data-previous-sheet]'));
                });
        }
    }

    show () {
        return this.setVisibility(true);
    }
    hide () {
        return this.setVisibility(false);
    }
    toggleVisibility () {
        return this.setVisibility(!this.classList.contains(MenuSheet.visible));
    }
    setVisibility (value) {
        return this.ensureLoaded(value)
            .then(() => this.classList.toggle(MenuSheet.visible, value));
    }

    addNavigateDownListener(element) {
        element.addEventListener("click", (e) => {
            const sheet = document.getElementById(element.dataset.nextSheet);
            if (!!sheet) {
                const menu = e.target.closest("nav-mobile-menu");
                sheet.show().then(() => menu.shiftRight(1));
                track(sheet, "user-action/menu-navigate-down");
            }
        });
    }

    addNavigateUpListener(element) {
        element.addEventListener('click', () => {
            const menu = element.closest(MobileMenu.customElement);
            const sheet = element.closest(MenuSheet.customElement);
            menu.shiftRight(-1);
            setTimeout(() => sheet.hide(), 350);
            track(sheet, "user-action/menu-navigate-up");
        });
    }

    addVisitMenuListener(element) {
        element.addEventListener("click", () => {
            track(element, "user-action/visit-menu-link");
        });
    }
}

export class MobileMenuButton extends HTMLElement {
    static customElement = "nav-mobile-menu-button";

    constructor() {
        super();
    }

    connectedCallback() {
        this.open = this.dataset.open || false;
        this.toggleMenu = () => {
            this.open = !this.open;
            const menuWrapper = document.getElementById(this.dataset.menuWrapper);
            if (this.open) {
                document.getElementsByTagName("body")[0].classList.add("nav__body--scrolling-disabled");
                this.classList.add("nav_menu-toggle-button--opened");
                if (menuWrapper) menuWrapper.classList.add("nav_mobile-menu-wrapper--open");

            } else {
                document.getElementsByTagName("body")[0].classList.remove("nav__body--scrolling-disabled");
                this.classList.remove("nav_menu-toggle-button--opened");
                if (menuWrapper) menuWrapper.classList.remove("nav_mobile-menu-wrapper--open");
            }

            const controlledElement = document.getElementById(this.dataset.controls);
            const menuElement = document.getElementById(this.dataset.menuRoot);

            if (menuWrapper) {
                menuWrapper.style.setProperty('--nav-menu-top-offset', calculateTopOffset(menuWrapper));
            }

            const allSheets = menuElement.querySelectorAll("nav-menu-sheet");

            allSheets.forEach(sheet => { if(sheet.hide) sheet.hide() });
            if(menuElement && menuElement.resetShift) menuElement.resetShift();
            if(menuElement && menuElement.toggleVisibility) menuElement.toggleVisibility();
            if(controlledElement && controlledElement.toggleVisibility) controlledElement.toggleVisibility();
        };
        if(!!this.dataset.controls) {
            this.addEventListener('click', () => {
                this.toggleMenu();

                if (this.open) {
                    track(this, "user-action/menu-open");
                } else {
                    track(this, "user-action/menu-close");
                    window.o_global.eventQBus.emit("ftnav.global-navigation.closed");
                }
            });
        }
        window.o_global.eventQBus.on('ftnav.global-navigation.open', () => {
            if (!this.open) {
                this.toggleMenu();
                track(this, "user-action/menu-open");
            }
        });
        window.o_global.eventQBus.on('ftnav.global-navigation.close', (opts) => {
            if (this.open) {
                this.toggleMenu();
                if (opts && opts.interaction === "button") {
                    track(this, "user-action/menu-close");
                } else {
                    track(this, "user-action/menu-exit");
                }
                window.o_global.eventQBus.emit("ftnav.global-navigation.closed");
            }
        });
    }
}
