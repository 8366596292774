import { createEventMergeContext, submitEventMerge } from '@otto-ec/tracking-bct';

class AddToWishlistService {
    constructor(adjustTracking, firebaseTracking, toggles = { toggleActive: () => false }, baseUrl = '/wishlist-view/') {
        this._toggles = toggles;
        this._baseUrl = baseUrl;
        this._eventKeys = {
            add: 'ft1.wishlist.add',
            remove: 'ft1.wishlist.remove'
        };
        this._endpoints = {
            multiUpdate: this._baseUrl + 'items'
        };
        this._adjustTracking = adjustTracking;
        this._firebaseTracking = firebaseTracking;
    }

    init() {
        o_global.eventQBus.on(this._eventKeys.add, data => this._add(data));
        o_global.eventQBus.on(this._eventKeys.remove, data => this._removeList(data));
    }

    _add(data) {
        return this.addToWishlist(data.variationId, data.qualified, data.addedFrom, data.wishlistId);
    }

    _removeList(data) {
        if (data.variationIds.length > 0) {
            return this.multiRemoveItemsFromWishlist(data.variationIds, data.deletedFrom, data.wishlistId, data.deletionInteractionType);
        }
        return Promise.resolve();
    }

    async addToWishlist(variationId, qualified, client, wishlistId) {
        if (typeof variationId === 'undefined') {
            throw new Error("Required field 'variationId' missing. No Add2WL will be sent!");
        }
        if (typeof qualified === 'undefined') {
            throw new Error("Required field 'qualified' missing. No Add2WL will be sent!");
        }

        const operation = 'ADD';
        const context = await createEventMergeContext();
        const eventMergeId = context.eventMergeId;
        const data = {
            items: [
                {
                    variationId,
                    qualified
                }
            ],
            client,
            sourceUrl: window.location.pathname + window.location.search,
            wishlistId,
            operation,
            eventMergeId
        };

        const payload = {
            features: [],
            name: 'add'
        };

        submitEventMerge(eventMergeId, {}, payload);

        return o_util.ajax
            .post(this._endpoints.multiUpdate, data)
            .then(xhr => this._handleMultiUpdateResponse(xhr, true, [variationId], eventMergeId));
    }

    async multiRemoveItemsFromWishlist(variationIds, client, wishlistId, deletionInteractionType) {
        const sourceUrl = window.location.pathname + window.location.search;
        const context = await createEventMergeContext();
        const eventMergeId = context.eventMergeId;
        const items = variationIds.map(variationId => ({ variationId }));
        const operation = 'REMOVE';
        const data = {
            client,
            items,
            sourceUrl,
            eventMergeId,
            wishlistId,
            operation,
            deletionInteractionType: deletionInteractionType
        };

        const payload = {
            features: [],
            name: 'delete'
        };

        submitEventMerge(eventMergeId, {}, payload);

        return o_util.ajax
            .post(this._endpoints.multiUpdate, data)
            .then(xhr => this._handleMultiUpdateResponse(xhr, false, variationIds, eventMergeId));
    }

    _handleMultiUpdateResponse(xhr, isAddOperation, variationIds, eventMergeId) {
        const success = xhr.status === 200 && JSON.parse(xhr.responseJSON).success;
        if (success) {
            const responseJson = JSON.parse(xhr.responseJSON);
            this._signalWishlistChanged(responseJson.newAmount);
            this._trackExactag(isAddOperation, variationIds);
            this._trackAdjust(isAddOperation, responseJson.articleNumber, responseJson.variationLink);
            this._trackFirebase(isAddOperation, responseJson.articleNumber);
        } else {
            this._signalWishlistChangedEmpty();
        }
        this._sendStatusEvent(variationIds, success, isAddOperation, eventMergeId);
    }

    _sendStatusEvent(variationIds, success, isAddOperation, eventMergeId) {
        const topic = isAddOperation ? 'add' : 'remove';
        o_global.eventQBus.emit(`ft1.wishlist.${topic}_status`, {
            variationIds,
            success,
            eventMergeId
        });
    }

    _signalWishlistChanged(newAmount) {
        document.dispatchEvent(new CustomEvent('wishlist.changed', { detail: { newAmount } }));
    }

    /**
 *
 *
 */
    _signalWishlistChangedEmpty() {
        document.dispatchEvent(new CustomEvent('wishlist.changed'));
    }

    _trackExactag(isAddOperation, variationIds) {
        if (isAddOperation && lhotse && lhotse.exactag) {
            variationIds.forEach(variationId => {
                lhotse.exactag.data.pt = 'add_to_wishlist';
                lhotse.exactag.data.mv = variationId;
                lhotse.exactag.track();
            });
        }
    }

    _trackAdjust(isAddOperation, articleNumber, variationLink) {
        if (isAddOperation) {
            this._adjustTracking.trackAdjust(articleNumber, variationLink);
        }
    }

    _trackFirebase(isAddOperation, articleNumber) {
        if (isAddOperation) {
            this._firebaseTracking.trackFirebase(articleNumber);
        }
    }
}

export { AddToWishlistService };
