<script lang="ts">
  import type { FeatureTracking } from "./FeatureTracking";
  import type { FeatureTrackingReference } from "./FeatureTrackingReference";
  import MinMaxInput from "./custom-range/MinMaxInput.svelte";
  import SliderInput from "./custom-range/SliderInput.svelte";
  import type { Interval, FacetValue, FacetValueOptions } from "./type";
  import { SingleSelect } from "../facet-value-input";

  interface Props extends FacetValue, FacetValueOptions {
    selectedValues?: string;
    featureTracking?: FeatureTracking;
    availableRange: Required<Interval>;
    unit?: string;
    logarithmic?: boolean;
    useMinMax?: boolean;
    useSlider?: boolean;
    natural?: boolean;
  }

  let {
    id,
    name,
    checked: initialChecked,
    selectedValues = $bindable(),
    range: initialRange = {},
    featureTracking,
    availableRange,
    unit,
    logarithmic = false,
    useMinMax = true,
    useSlider = false,
    natural = false,
  }: Props = $props();

  let checked = $state(initialChecked);
  let range = $state(initialRange);
  let value = $derived(`${range.from || "_"},${range.to || "_"}`);

  function oninput() {
    checked = true;
  }

  /*                     */
  if (featureTracking) {
    if (!initialChecked) {
      delete featureTracking.status;
    }
  }

  const featureTrackingReference: FeatureTrackingReference = {
    ref: id,
    labels: {},
  };
</script>

<div class="hc_customRange hc_facetValue" data-facet-name={name} {oninput}>
  <label hidden>
    <SingleSelect {id} {checked} class="hc_customRange__input" {name} {value} bind:selectedValues {featureTracking} />
  </label>
  {#if useMinMax}
    <MinMaxInput {unit} bind:range {availableRange} {featureTrackingReference} />
  {/if}
  {#if useSlider}
    <SliderInput bind:range {availableRange} {logarithmic} {natural} {featureTrackingReference} />
  {/if}
</div>

<style lang="scss">
  .hc_customRange {
    display: flex;
    flex-direction: column;
    gap: calc(var(--oc-semantic-spacing-150) - var(--oc-semantic-spacing-25));
  }
</style>
