import { eventQBus } from "../types/EventQBus";
import type { DresonRuleResolvedEvent } from "./FilterTypes";
import { LoadFilter } from "./LoadFilter";
import {
  moveInitialFacetVisibilities,
  onFilterVisibilitiesLoad,
  updateFilterVisibilitiesFromPersisted,
} from "./LoadFilterVisibilities";
import { TsBct } from "./TsBct";
import { addFeaturesToPageImpressionTracking } from "../tracking/FeatureTracking";
import { Filter } from "../filter/Filter";
import { addSanErrorToPageImpression, FILTER_FEATURES } from "../filter/FilterTracking";
import { Facet } from "../filter/Facet";
import { FilterSection } from "../filter/FilterSection";

export function registerFilterReloading() {
  eventQBus.on("ftfind.dresonRule.resolved", (data: DresonRuleResolvedEvent) => {
    if (data && data.rule !== FilterSection.template().currentDresonRule) {
      if (data.count === 0) {
        Filter.handleError();
        addSanErrorToPageImpression("filterlist_empty");
        addFeaturesToPageImpressionTracking(FILTER_FEATURES);
      } else {
        eventQBus.emit("heureka.filterSection.load", { ...data, ssid: TsBct.sfid() });
      }
    } else {
      if (history.state?.categories && history.state.categories.length > 0) {
        Facet.facetName("kategorien")?.reInitializeFacetValuesFrom(history.state.categories);
      }
    }
  });

  eventQBus.on("ftfind.dresonRule.resolveAborted", Filter.handleError);

  eventQBus.on("heureka.filterSection.load", (event) => {
    const filtersLoadPromise = LoadFilter.onLoadFilters(event);
    if (FilterSection.isCrispyFiltersListActive()) {
      filtersLoadPromise.then(() => {
        eventQBus.emit("heureka.filterSection.loaded");
      });
    } else {
      const filterVisibilitiesLoadPromise = onFilterVisibilitiesLoad(event);
      Promise.all([filtersLoadPromise, filterVisibilitiesLoadPromise]).then(() => {
        updateFilterVisibilitiesFromPersisted();
        eventQBus.emit("heureka.filterSection.loaded");
      });
    }
  });
  eventQBus.once("heureka.filters.loaded", moveInitialFacetVisibilities);

  eventQBus.on("heureka.filterSection.initialLoaded", LoadFilter.onInitialLoadedFilters);
}
