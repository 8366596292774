<script lang="ts">
  import type {SqrlListItemDetails, SqrlListItemModel} from "../../../types/squirrel";
  import {createEventDispatcher} from "svelte";
  import {SqrlQaId} from "../../../constants/squirrel";
  import {activeIndex} from "../../../store/IndexStore";
  import SqrlListItemIcon from "./SqrlListIcon.svelte";

  export let params: SqrlListItemModel;

  const dispatch = createEventDispatcher();

  function handleClick(type: string) {
    dispatch(type, {...params.suggestion, index: params.index} as SqrlListItemDetails)
  }
</script>

<li class="squirrel_listItem squirrel_{params.name}"
    data-qa-id={SqrlQaId.SUGGEST_ITEM}
    data-testid="{params.name}-{params.index}">
    <div class="squirrel_listItem__row js_squirrel_{params.name} squirrel_{params.name}__text oc-copy-100"
         data-qa-id={SqrlQaId.SUGGEST_ROW}>
        <SqrlListItemIcon params={params.leftIcon} on:click={() => handleClick("clickLeftIcon")}/>
        <div class="squirrel_listItem__term js_squirrel_{params.name}__term squirrel_{params.name}__term"
             class:squirrel_active={params.index === $activeIndex}
             data-pos={params.index}
             data-suggestpos={params.index + 1}
             data-target={params.suggestion.target}
             data-term={params.suggestion.term}
             on:click|preventDefault={() => handleClick("clickItem")}
             on:keydown|preventDefault
             role="button"
             tabindex="-1">{@html params.suggestion.visual}</div>
        <SqrlListItemIcon params={params.rightIcon} on:click={() => handleClick("clickRightIcon")}/>
    </div>
</li>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  .squirrel_active {
    color: tokens.$oc-base-color-otto-red-100;
  }

  .squirrel_listItem {
    position: relative;
    cursor: pointer;
    background-color: tokens.$oc-base-color-white;

    &:hover {
      background-color: tokens.$oc-base-color-gray-25;
    }

    &:not(:last-child):after {
      display: block;
      content: "";
      margin: tokens.$oc-semantic-spacing-0 tokens.$oc-semantic-spacing-100;
      border-bottom: tokens.$oc-semantic-border-width-100 solid tokens.$oc-base-color-gray-25;
    }
  }

  .squirrel_listItem__row {
    display: flex;
    height: tokens.$oc-base-dimension-48;
    flex-direction: row;
    width: 100%;

    & > :first-child {
      padding-left: tokens.$oc-semantic-spacing-100;
    }

    & > :last-child {
      padding-right: tokens.$oc-semantic-spacing-100;
    }
  }

  .squirrel_listItem__term {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
    flex: 1;
    padding: tokens.$oc-semantic-spacing-75 tokens.$oc-semantic-spacing-0;
  }
</style>