<script lang="ts">
  import HistoryListItem from "./HistoryListItem.svelte";
  import HistoryListItemDelete from "./HistoryListItemDelete.svelte";
  import {visualSearches} from "../../../store/HistoryStore";
  import {SqrlTestId} from "../../../constants/squirrel";
  import {timeSuggestRender} from "../../../times/TimeFactory";
  import {Label} from "../../../constants/label";
  import {indexStore} from "../../../store/IndexStore";
  import {onDestroy} from "svelte";

  const unsubscribe = visualSearches.subscribe((searches) => {
    indexStore.updateMaxIndex(searches.length);
  })

  timeSuggestRender.measure();

  onDestroy(() => {
    unsubscribe();
  })
</script>

<span class="js_squirrel_history_layer">
    <div class="squirrel_searchSuggestions__headline squirrel_border_top">
        <span class="oc-copy-75">{Label.HISTORY_HEADER}</span>
    </div>
    <ul class="squirrel_searchSuggestions__list" data-testid={SqrlTestId.HISTORY_LIST}>
        {#each $visualSearches as search, index}
            <HistoryListItem {search} {index}/>
        {/each}
        <HistoryListItemDelete></HistoryListItemDelete>
    </ul>
</span>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  .squirrel_searchSuggestions__headline {
    background-color: tokens.$oc-base-color-white;
    padding: tokens.$oc-semantic-spacing-150 tokens.$oc-semantic-spacing-100 tokens.$oc-semantic-spacing-50;
    height: auto;
    color: tokens.$oc-base-color-gray-400;
    display: flex;
    align-items: center;

    > span {
      flex: 0 0 50%;
      padding-right: tokens.$oc-semantic-spacing-50;
    }
  }
</style>
