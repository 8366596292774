import "./styles/global.scss";
import Stomachshop from "./Stomachshop.svelte";
import {OpenAPI} from "./generated/core/OpenAPI";
import {hydrate} from "svelte";

/*                                      */
OpenAPI.BASE = window.location.origin;

hydrate(Stomachshop, {
  target: document.getElementById("js_squirrel_stomachshop") as HTMLElement,
})