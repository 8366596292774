<script lang="ts">
  import type { FacetValueLabel, FacetValueLabelOptions } from "./type";

  interface Props extends FacetValueLabel, FacetValueLabelOptions {}

  let { title, itemCount, fitContent = true, size = "100", bold }: Props = $props();
</script>

<span class={["hc_label", `hc_label--${size}`, { "hc_label--grow": !fitContent }]} data-count={itemCount}
  ><span class={["hc_label__title", { "hc_label__title--bold": bold }]}>{title}</span></span
>

<style lang="scss">
  .hc_label {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: var(--oc-semantic-spacing-50);
    overflow: hidden;
    font: var(--label-font);

    &--50 {
      --label-font: var(--oc-semantic-font-copy-50);
    }

    &--75 {
      --label-font: var(--oc-semantic-font-copy-75);
    }

    &--100 {
      --label-font: var(--oc-semantic-font-copy-100);
    }

    &--125 {
      --label-font: var(--oc-semantic-font-copy-125);
    }

    &--grow {
      justify-content: space-between;
      width: 100vw; /*                           */
    }

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 0 1 min-content;

      &--bold {
        font-weight: 700;
      }
    }

    &[data-count]::after {
      flex: 0 0 max-content;
      color: var(--oc-semantic-color-text-secondary);
    }

    &:not(&--grow)[data-count]::after {
      content: "(" attr(data-count) ")";
    }

    &--grow[data-count]::after {
      content: attr(data-count);
    }
  }
</style>
