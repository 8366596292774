<script lang="ts">
  import type { ClassValue } from "svelte/elements";
  import type { FacetValueInput } from "./type.ts";
  import { addTrackingAttributes } from "../facet-value/Tracking.ts";
  import inputDefault from "../facet-value/InputDefaultAction.ts";

  interface Props extends FacetValueInput {
    id?: string;
    class?: ClassValue;
    selectedValues?: string;
  }

  let {
    name,
    value,
    checked,
    featureTracking,
    id = `${name}_${featureTracking?.id}`,
    selectedValues = $bindable(),
    ...rest
  }: Props = $props();

  /*                     */
  if (featureTracking) {
    if (!checked) {
      delete featureTracking.status;
    }
  }

  function onchange({ currentTarget }: Event & { currentTarget: HTMLInputElement }) {
    selectedValues = currentTarget.value;
  }

  const type = featureTracking?.method || "radio_button";
</script>

<input
  {id}
  class={["js_tValue", rest.class]}
  {name}
  type="radio"
  {value}
  {checked}
  data-ts-feature-filter-method={type}
  use:addTrackingAttributes={featureTracking}
  use:inputDefault={true}
  {onchange}
/>
