import FilterResolveDresonRule from "./FilterResolveDresonRule";
import { DresonRuleResolvedEvent, OrchestrationTopics } from "../../types/FilterTypes";
import LoadLocalNavigation from "./LoadLocalNavigation";
import LoadBreadCrumb from "./LoadBreadCrumb";
import LoadNavConsultantText from "./LoadNavConsultantText";
import LoadNavBottomLinks from "./LoadNavBottomLinks";
import LoadNavSidebarText from "./LoadNavSidebarText";
import OttoUp from "./OttoUp";
import LoadCategorySlider from "./LoadCategorySlider";
import { eventQBus } from "../../types/EventQBus";
import LoadSeoMetaBlock from "./LoadSeoMetaBlock";

export default class MultiFiltering {
  private static hasBeenInitialized = false;

  public static init() {
    if (MultiFiltering.hasBeenInitialized) {
      return;
    }
    MultiFiltering.hasBeenInitialized = true;

    eventQBus.on("ftfind.dresonRule.resolve", FilterResolveDresonRule.resolve);

    eventQBus.on("ftfind.dresonRule.resolved", (data: DresonRuleResolvedEvent) => {
      /*                                                        */
      if (data.count === 0) {
        return;
      }
      eventQBus.emit("ftfind.tilelist.load", data);
    });

    MultiFiltering.onResolved("ftfind.breadcrumb.load");
    eventQBus.on("ftfind.breadcrumb.load", LoadBreadCrumb.onLoadBreadcrumb);

    MultiFiltering.onResolved("ftfind.localNavigation.load");
    eventQBus.on("ftfind.localNavigation.load", LoadLocalNavigation.onLoadLocalNavigation);

    MultiFiltering.onResolved("ftfind.categorySlider.load");
    eventQBus.on("ftfind.categorySlider.load", LoadCategorySlider.onLoadCategorySlider);

    MultiFiltering.onResolved("ftfind.navConsultantText.load");
    eventQBus.on("ftfind.navConsultantText.load", LoadNavConsultantText.onLoadNavConsultantText);

    MultiFiltering.onResolved("ftfind.navBottomLinks.load");
    eventQBus.on("ftfind.navBottomLinks.load", LoadNavBottomLinks.onLoadNavBottomLinks);

    MultiFiltering.onResolved("ftfind.navSidebarText.load");
    eventQBus.on("ftfind.navSidebarText.load", LoadNavSidebarText.onLoadNavSidebarText);

    MultiFiltering.onResolved("ftfind.ottoUp.load");
    eventQBus.on("ftfind.ottoUp.load", OttoUp.onLoadOttoUp);

    MultiFiltering.onResolved("reptile.seoMetaBlock.load");
    eventQBus.on("reptile.seoMetaBlock.load", LoadSeoMetaBlock.onLoadSeoMetaBlock);
  }

  private static onResolved(topic: keyof OrchestrationTopics) {
    eventQBus.on("ftfind.dresonRule.resolved", (data: DresonRuleResolvedEvent) => {
      if (data.count === 0) {
        return;
      }

      eventQBus.emit(topic, data);
    });
  }
}
