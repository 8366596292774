<script lang="ts">
  import type {HistoryItem} from "../../../types/global";
  import type {TrackingData} from "../../../types/tracking";
  import {SqrlQaId} from "../../../constants/squirrel";
  import {SUBMIT_TYPE, submitStore} from "../../../store/SubmitStore";
  import {SqrlUrl} from "../../../util/SqrlUrl";
  import {tlSearchType} from "../../../tracking/labels/SearchType";
  import {SearchHistoryValue, TL_HIGGINS} from "../../../tracking/labels/higginsLabel";
  import {SEARCH_TYPE} from "../../../constants/searchType";
  import {COLOR} from "../../../constants/color";
  import {activeIndex} from "../../../store/IndexStore";

  export let search: HistoryItem;
  export let index: number;


  /**
 *
 *
 *
 *
 *
 */
  function handleSubmit() {
    const trackingData: TrackingData = {
      [tlSearchType.name]: SEARCH_TYPE.HISTORY,
      [TL_HIGGINS.ACTIVE_SEARCH]: ["true"],
      [TL_HIGGINS.INITIAL_SEARCH]: ["true"],
      [TL_HIGGINS.INTERACTION]: ["initial_search"],
      [TL_HIGGINS.SEARCH_HISTORY]: [SearchHistoryValue.EXISTS],
      [TL_HIGGINS.SUGGEST_POS]: [`${index + 1}`],
    }
    const fallbackTarget: SqrlUrl = search.target ? new SqrlUrl(`${window.location.origin}${search.target}`) : new SqrlUrl(`${window.location.origin}/suche/${search.term}/`)

    submitStore.handleSubmit(search, fallbackTarget.urlWithRef(trackingData), SUBMIT_TYPE.HISTORY_ITEM)
  }
</script>

<li class="squirrel_searchHistory"
    data-qa-id={SqrlQaId.HISTORY_ITEM}
    role="listitem">
    <div class="squirrel_searchHistory__text js_squirrel_searchHistory"
         data-qa-id={SqrlQaId.HISTORY_ITEM_ROW}
         role="button"
         tabindex="-1"
         on:keypress|preventDefault={handleSubmit}
         on:click|preventDefault={handleSubmit}>
        <oc-icon-v1 type="duration" size="100"></oc-icon-v1>

        <div data-term={search.term}
             class:squirrel_active={index === $activeIndex}
             data-pos={index}
             class="squirrel_searchHistory__term
                 js_squirrel_searchHistory__term
                 js_squirrel_ignore_close_suggest">
            <div class="oc-copy-100">{search.term}</div>{#if search.tags && search.tags.length > 0}
                <div class="oc-copy-75">
                    {search.tags.join(", ")}
                </div>
            {/if}
        </div>
        <oc-icon-v1 type="arrow-right" size="100" color={COLOR.RED_100}></oc-icon-v1>
    </div>
</li>

<style lang="scss">
  @use "@otto-ec/design-tokens/tokens";

  oc-icon-v1 {
    padding: tokens.$oc-semantic-spacing-100;
    width: tokens.$oc-base-dimension-56;
    height: tokens.$oc-base-dimension-56;
  }

  .squirrel_active {
    color: tokens.$oc-base-color-otto-red-100;
  }

  .squirrel_searchHistory {
    position: relative;
    cursor: pointer;
    background-color: tokens.$oc-base-color-white;

    &:not(:last-child):after {
      display: block;
      content: "";
      margin: tokens.$oc-semantic-spacing-0 tokens.$oc-semantic-spacing-100;
      border-bottom: tokens.$oc-semantic-border-width-100 solid tokens.$oc-base-color-gray-25;
    }


    &__text {
      display: flex;
      height: tokens.$oc-base-dimension-56;

      justify-content: center;
      align-items: center;


      .squirrel_searchHistory__term {
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &:hover {
      background-color: tokens.$oc-base-color-gray-25;
    }
  }
</style>
