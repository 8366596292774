<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { Facet, FacetOptions } from "./type.ts";
  import { List } from "../facet-value-group";

  import { addTrackingAttributes } from "../facet-value/Tracking.ts";

  interface Props extends Facet, FacetOptions {}

  let { name, values, featureTracking, contentSnippet = defaultList, scrollable = true }: Props = $props();

  const isSelected = (value: FacetValue) => value.checked;
  const isNotSelected = (value: FacetValue) => !isSelected(value);
  const addNameIfMissing = (value: FacetValue) => ({ name, ...value });

  const selectedValues = $derived(values.filter(isSelected).map(addNameIfMissing));
  const unselectedValues = $derived(values.filter(isNotSelected).map(addNameIfMissing));
</script>

{#snippet defaultList(values: FacetValue[])}
  <List {values}></List>
{/snippet}

<fieldset {name} class="hc_facet" id="facet_materialdescription" use:addTrackingAttributes={featureTracking}>
  {@render contentSnippet(selectedValues)}
  <div class={["hc_facet__scrollBox", { "hc_facet__scrollBox--scrollable": scrollable }]}>
    {@render contentSnippet(unselectedValues)}
  </div>
</fieldset>

<style lang="scss">
  fieldset {
    min-width: 0;
  }

  .hc_facet__scrollBox {
    &--scrollable {
      max-height: var(--scroll-area-height, 365px);
      overflow-y: auto;
      margin-left: calc(-2 * var(--oc-semantic-focus-outline-offset));
      padding-left: calc(2 * var(--oc-semantic-focus-outline-offset));
    }
  }
</style>
