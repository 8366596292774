import {derived, get, type Writable, writable} from "svelte/store";
import type {HistoryItem} from "../types/global";
import {sqrlStorage} from "../util/SqrlStorage";
import {STORAGE} from "../constants/storage";
import {sqrlConsentBanner} from "../util/SqrlConsentBanner";
import * as protobufHistory from "@otto-ec/squirrel_protobuf-history";

/**
 *
 */
class HistoryStore {
  private readonly maxTotalLength: number;
  private readonly _store: Writable<HistoryItem[]>;

  constructor(maxTotalLength: number = 10) {
    this.maxTotalLength = maxTotalLength;
    /*                                     */
    this._store = writable(this.maybeLoadInitial(), (set) => {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        /*                                             */
        const handleStorage = (event: StorageEvent) => {
          if (event.key === STORAGE.HISTORICAL_DATA) set(this.maybeLoadInitial());
        };
        window.addEventListener("storage", handleStorage);

        return () => window.removeEventListener("storage", handleStorage);
      }
    });
  }

  /**
 *
 *
 *
 */
  private writeToStorage(searches: HistoryItem[]) {
    const searchString: string = JSON.stringify(searches);
    sqrlStorage.setItem(STORAGE.HISTORICAL_DATA, searchString);
  }

  /**
 *
 *
 */
  private maybeLoadInitial() {
    const searchesString: string | null = sqrlStorage.getItem(STORAGE.HISTORICAL_DATA);
    if (!searchesString) {
      return [];
    }
    const historySearchItems: HistoryItem[] = JSON.parse(searchesString);
    return historySearchItems.sort((a, b) => b.date - a.date);
  }

  /**
 *
 *
 *
 *
 */
  private deletePositions(indexToDelete: number, last: HistoryItem[]) {
    return last.filter((_, index) => {
      return indexToDelete !== index;
    });
  }

  /**
 *
 *
 *
 *
 *
 */
  private add(item: HistoryItem | { term: string }): (last: HistoryItem[]) => HistoryItem[] {
    return (last: HistoryItem[]) => {
      if (!item) {
        return last;
      }
      const newSearch: HistoryItem = {
        term: item.term.toLowerCase(),
        target: (item as HistoryItem)?.target,
        tags: (item as HistoryItem)?.tags,
        date: new Date().getTime(),
      };

      let searches: HistoryItem[] = last;

      const duplicateEntry = this.currentlyLastItemInHistory();

      if (duplicateEntry != -1) {
        searches = this.deletePositions(duplicateEntry, searches);
      }
      searches.unshift(newSearch);
      if (searches.length > this.maxTotalLength) {
        searches.pop();
      }
      return searches;
    };
  }

  /**
 *
 *
 */
  currentlyLastItemInHistory(): number {
    const lastItem = get(this.store)[0];
    return get(this.store).findIndex((item: HistoryItem, index: number) => {
      if (index === 0) {
        return;
      }
      if (lastItem.term === item.term) {
        return (
          !(lastItem.tags || item.tags) ||
          (lastItem.tags &&
            item.tags &&
            lastItem.tags.sort().join(" ") == item.tags.sort().join(" "))
        );
      }
    });
  }

  /**
 *
 *
 */
  set(value: HistoryItem[]) {
    this._store.set(value);
    this.writeToStorage(value);
  }

  /**
 *
 */
  subscribe() {
    return this._store.subscribe;
  }

  /**
 *
 *
 */
  update(callback: (value: HistoryItem[]) => HistoryItem[]) {
    return this._store.update((last) => {
      if (sqrlConsentBanner.isEnabled) {
        const value = callback(last);
        this.writeToStorage(value);
        return value;
      } else {
        return last;
      }
    });
  }

  /**
 *
 *
 */
  addItem(item: HistoryItem | { term: string }) {
    this.update(this.add(item));
  }

  /**
 *
 *
 */
  deleteHistory() {
    this.set([]);
    const inputElement: HTMLInputElement | null = document.querySelector(
      ".js_squirrel_searchbar__input",
    );
    inputElement?.focus();
  }

  /**
 *
 */
  reInitialize() {
    this.set(this.maybeLoadInitial());
  }

  get store() {
    return this._store;
  }

  /**
 *
 *
 *
 *
 *
 */
  updateTarget(rule: string, redirect: string) {
    /*                                                       */
    if (!redirect || !rule) {
      return;
    }
    const firstIndex = rule.indexOf("(suchbegriff.") + 13;
    const match = decodeURI(rule.substring(firstIndex, rule.indexOf(")", firstIndex)));
    if (match === get(this.store)[0]?.term) {
      if (redirect.split("/").filter((pathPart) => pathPart.length > 0).length <= 2) {
        this.updateTags(rule, []);
      }
      this.update((last: HistoryItem[]) => {
        last[0].target = redirect;
        return last;
      });
    }
  }

  /**
 *
 *
 *
 *
 *
 */
  updateTags(rule: string, tags: string[]) {
    const firstIndex = rule.indexOf("(suchbegriff.") + 13;
    const match = decodeURI(rule.substring(firstIndex, rule.indexOf(")", firstIndex)));
    if (match === get(this.store)[0]?.term) {
      this.update((last: HistoryItem[]) => {
        if (tags?.length === 0) {
          last[0].tags = undefined;
        } else {
          last[0].tags = tags;
        }
        return last;
      });
    }
  }
}

/**
 *
 */
export const submitHistory = new HistoryStore(30);
/**
 *
 *
 *
 *
 *
 */
export const visualSearches = derived(submitHistory.store, ($submitHistory) => {
  /*                                                                                                                                */
  const uniqueSearches: { [key: string]: HistoryItem } = {};
  for (let i = 0; i < $submitHistory.length; i++) {
    const search = $submitHistory[i];
    const key = `${search.term}_${search.tags?.join(",")}`;
    if (!uniqueSearches[key] || uniqueSearches[key].date < search.date) {
      uniqueSearches[key] = search;
    }
  }
  const uniqueSearchesArray = Object.values(uniqueSearches).sort((a, b) => b.date - a.date);
  return uniqueSearchesArray.slice(0, 5);
});

/**
 *
 */
export const isEmpty = derived(submitHistory.store, ($submitHistory) => {
  return $submitHistory.length === 0;
});

export function getLengthOfHistoryItems(): number {
  return get(submitHistory.store).length;
}

/**
 *
 *
 */
const getFilteredHistory = () => {
  const history = get(submitHistory.store);
  const historyWithoutDuplicates = history.filter(
    (item, index, self) => index === self.findIndex((t) => t.term === item.term),
  );
  return historyWithoutDuplicates.slice(0, 10);
};

export const encodedHistory: () => string | undefined = () => {
  try {
    if (sqrlConsentBanner.isEnabled) {
      const history: protobufHistory.protoHistory.History = protobufHistory.createHistory(
        getFilteredHistory(),
        Date.now(),
      );

      const binary: Uint8Array = protobufHistory.protoHistory.History.encode(history).finish();
      return btoa(String.fromCodePoint(...binary));
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
};
