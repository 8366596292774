<script lang="ts">
  import {Label} from "../../constants/label";
  import {isFocus, isSuggestOpen} from "../../store/SearchfieldStore";
  import {SqrlTestId} from "../../constants/squirrel";


  /**
 *
 *
 */
  function handleAbort(event: Event) {
    event.preventDefault();
    if (event instanceof KeyboardEvent && event.key !== "Enter") {
      return;
    }
    $isFocus = false;
    $isSuggestOpen = false;
  }
</script>

{#if $isFocus}
  <span class="oc-copy-100 squirrel_searchline__abort js_squirrel_searchline__abort"
        data-testid={SqrlTestId.ABORT_BUTTON}
        role="button"
        on:click={handleAbort}
        on:keydown={handleAbort}>{Label.ABORT_BUTTON}</span>
{/if}

<style lang="scss">
  @use "~@otto-ec/design-tokens/tokens";
  @use "~@otto-ec/otto-components-utils/scss/_mixins/breakpoint";

  .squirrel_searchline__abort {
    display: block;
    padding-left: tokens.$oc-semantic-spacing-50;
    color: tokens.$oc-semantic-color-text-interactive;
    cursor: pointer;

    @include breakpoint.breakpoint-from-l($to: false) {
      display: none;
    }
  }
</style>
