<script lang="ts">
  import type { Facet, FacetOptions } from "@otto-ec/heureka-components/facet";
  import type { FacetValueLabelOptions } from "@otto-ec/heureka-components/facet-value-label";
  import type { FacetValueGroupOptions } from "@otto-ec/heureka-components/facet-value-group";
  import type { FacetValue, FacetValueOptions } from "@otto-ec/heureka-components/facet-value";
  import { Scrollable } from "@otto-ec/heureka-components/facet";
  import { Text } from "@otto-ec/heureka-components/facet-value-label";
  import { List, OverflowBox } from "@otto-ec/heureka-components/facet-value-group";
  import { Checkbox, Radio } from "@otto-ec/heureka-components/facet-value";
  import type { Snippet } from "svelte";

  interface Props extends Facet {
    mode?: "filterAccordion" | "detailSheet";
  }

  const { mode = "filterAccordion", featureTracking, ...facet }: Props = $props();

  /*                       */
  type FacetValueType = "checkbox" | "radio";
  type FacetValueGroupType = "list" | "overflowBox";

  /*                           */
  const facetValueType: FacetValueType = "checkbox";
  const facetValueGroupType: FacetValueGroupType = "list";

  /*        */
  const facetItemMap: Record<FacetValueType, Snippet<[FacetValue]>> = {
    checkbox,
    radio,
  };
  const facetValueGroupMap: Record<FacetValueGroupType, Snippet<[FacetValue[]]>> = {
    list,
    overflowBox,
  };

  /*                */
  const facetValueLabelOptions: FacetValueLabelOptions = {
    fitContent: false,
    size: 125,
  };
  const facetValueOptions: FacetValueOptions = {};
  const facetGroupOptions: FacetValueGroupOptions = {
    item: facetItemMap[facetValueType],
  };
  const facetOptions: FacetOptions = {
    contentSnippet: facetValueGroupMap[facetValueGroupType],
    scrollable: false,
  };
  /*                                           */

  const featureTrackingWithParent = { ...featureTracking, parentId: "find_filter_material" };
</script>

{#snippet checkbox(facetValue: FacetValue)}
  <Checkbox {...facetValue} {...facetValueOptions}>
    <Text {...facetValue} {...facetValueLabelOptions} />
  </Checkbox>
{/snippet}

{#snippet radio(facetValue: FacetValue)}
  <Radio {...facetValue} {...facetValueOptions}>
    <Text {...facetValue} {...facetValueLabelOptions} />
  </Radio>
{/snippet}

{#snippet list(values: FacetValue[])}
  <List {values} {...facetGroupOptions} />
{/snippet}

{#snippet overflowBox(values: FacetValue[])}
  <OverflowBox {values} {...facetGroupOptions} />
{/snippet}

{#if mode === "detailSheet"}
  <Scrollable {...facet} {...facetOptions} featureTracking={featureTrackingWithParent} />
{:else}
  <Scrollable {...facet} featureTracking={featureTrackingWithParent} />
{/if}
