import { readLegacyLabels } from "../tracking/FeatureTracking";
import { eventQBus } from "../types/EventQBus";
import { move, moveAllChildren } from "../util/Utils";
import { filterFooter, filterForm } from "../filtersheet/Selectors";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { updateFeatureStatus } from "./FilterTracking";
import { filterHeaderButtons } from "../filtersheet/FilterSheetHeaderButtons";
import { readSanPopularFacetValues, updateSanPopularFacetValues } from "./PopularFacetValues";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { Filter } from "./Filter";
import type { FacetVisibilityAware } from "./FacetVisibilityAware";
import { isMobile } from "../util/Breakpoint";
import { FilterAccordions } from "./FilterAccordions";
import { updateSanFilterWhatIf } from "../tracking/WhatIfTracking";
import tracker from "../tracking/Tracker";
import { FilterSection } from "./FilterSection";

const FILTER_ACCORDION_CLASS = "heureka_filterAccordion";
const FILTER_ACCORDION_OPEN_ON_MOBILE_CLASS = `${FILTER_ACCORDION_CLASS}--openOnMobile`;

const PL_ACCORDION_HEADER_SELECTOR = ".pl_accordion__header";
const PL_ACCORDION_HEADER_OPEN_CLASS = "pl_accordion__header--open";
const PL_ACCORDION_CONTENT_SELECTOR = ".heureka_filterAccordion__content";

export class FilterAccordion implements FacetVisibilityAware {
  static readonly factory = HeurekaElementFactory.byClass(FILTER_ACCORDION_CLASS, FilterAccordion);

  constructor(readonly accordion: HTMLElement) {}

  /*                  */

  static filterId(id: string, root?: ParentNode | null) {
    return FilterAccordion.factory.pick(`.${FILTER_ACCORDION_CLASS}[data-filter-id="${id}"]`, root);
  }

  static invalidFilterIds() {
    return FilterAccordion.factory
      .all()
      .filter((accordion) => accordion.invalid)
      .map((accordion) => accordion.filterId);
  }

  /*               */

  static register() {
    eventQBus.on("assets.accordion.toggled", FilterAccordion.handleAccordionToggle);
    eventQBus.once("heureka.filterSection.loaded", FilterAccordion.#initAll);
  }

  static #initAll() {
    if (isMobile()) {
      updateFeatureStatus("visible", "loaded");
      FilterAccordion.factory.forEach(FilterAccordion.#initMobile);
    }
  }

  static #initMobile(accordion: FilterAccordion) {
    accordion.openMobile = false;
  }

  static prepareAll(fragment: DocumentFragment, initialLoad = false) {
    if (isMobile()) {
      FilterAccordion.factory.forEach(FilterAccordion.#prepareMobile, fragment);
    } else {
      FilterAccordion.factory.forEach((accordion) => FilterAccordion.#prepare(accordion, initialLoad), fragment);
    }
  }

  static #prepareMobile(accordion: FilterAccordion) {
    const oldAccordion = FilterAccordion.filterId(accordion.filterId);
    if (oldAccordion) {
      accordion.openMobile = oldAccordion.openMobile;
    }
  }

  static #prepare(accordion: FilterAccordion, initialLoad = false) {
    const oldAccordion = FilterAccordion.filterId(accordion.filterId);
    if (oldAccordion) {
      accordion.open = oldAccordion.open;
      accordion.hidden = oldAccordion.hidden;
    } else {
      if (!initialLoad && !FilterSection.isCrispyFiltersListActive()) {
        accordion.hidden = true;
      }
    }
  }

  /*                       */

  get filterId(): string {
    return this.accordion.dataset.filterId || "";
  }

  private get accordions() {
    return FilterAccordions.template();
  }

  get header() {
    return this.accordion.querySelector<HTMLElement>(PL_ACCORDION_HEADER_SELECTOR);
  }

  get content() {
    return this.accordion.querySelector<HTMLElement>(PL_ACCORDION_CONTENT_SELECTOR) || null;
  }

  get filterHeaderButtons(): HTMLElement | null {
    return filterHeaderButtons(this.accordion);
  }

  set filterHeaderButtons(source) {
    moveAllChildren(source, this.filterHeaderButtons, true);
  }

  get filterFooter(): HTMLElement | null {
    return filterFooter(this.accordion);
  }

  set filterFooter(source) {
    moveAllChildren(source, this.filterFooter, true);
  }

  get filterForm(): HTMLElement | null {
    return filterForm(this.accordion);
  }

  set filterForm(form) {
    move(form, this.content);
  }

  /**
 *
 */
  get filter(): Filter | undefined {
    return Filter.filterId(this.filterId);
  }

  /**
 *
 */
  get nestedFilter(): Filter | undefined {
    return Filter.factory.pick(undefined, this.accordion);
  }

  set nestedFilter(filter) {
    if (filter) {
      move(filter.form, this.content);
    }
  }

  get invalid() {
    return !!this.accordion.querySelector(".find_filterMessage[data-message-text]");
  }

  get alwaysOpenOnMobile() {
    return this.accordion.classList.contains(FILTER_ACCORDION_OPEN_ON_MOBILE_CLASS);
  }

  get openMobile(): boolean {
    return this.alwaysOpenOnMobile || !this.header;
  }

  set openMobile(state: boolean) {
    const oldState = this.#forceHeaderState(state);
    if (oldState != state || this.alwaysOpenOnMobile) {
      this.filterFoldoutStatus = oldState === undefined || this.alwaysOpenOnMobile ? "initial_opened" : state;
    }
  }

  get open(): boolean {
    const header = this.header;
    /*                                                    */
    return !header || header.classList.contains(PL_ACCORDION_HEADER_OPEN_CLASS);
  }

  set open(state: boolean) {
    const oldState = this.#forceHeaderState(state);
    if (oldState != state) {
      this.filterFoldoutStatus = oldState === undefined ? "initial_opened" : state;
    }
  }

  set filterFoldoutStatus(value: boolean | TrackingLabels["san_FilterFoldoutStatus"]) {
    const filter = this.nestedFilter;
    if (filter) {
      if (typeof value !== "boolean") {
        filter.filterFoldoutStatus = value;
      } else {
        filter.filterFoldoutStatus = value ? "opened" : "closed";
      }
    }
  }

  set sanPopularFacetValues(value: TrackingLabels["san_PopularFacetValues"]) {
    updateSanPopularFacetValues(this.accordion, value);
  }

  set sanFilterWhatIf(value: TrackingLabels["san_FilterWhatIf"]) {
    updateSanFilterWhatIf(this.accordion, value);
  }

  get hidden() {
    return this.accordion.hidden;
  }

  set hidden(hidden) {
    this.accordion.hidden = hidden;
  }

  #forceHeaderState(state: boolean) {
    const accordionHeader = this.header;
    if (accordionHeader) {
      const oldState = accordionHeader.classList.contains(PL_ACCORDION_HEADER_OPEN_CLASS);
      accordionHeader.classList.toggle(PL_ACCORDION_HEADER_OPEN_CLASS, state);
      return oldState;
    }
    /*                                     */
  }

  updateVisibility(shouldHide: boolean) {
    this.hidden = shouldHide;
  }

  remove() {
    this.accordion.remove();
  }

  private trackAccordionToggle() {
    const { filterId, open } = this;
    this.filterFoldoutStatus = open;
    let trackingData: Partial<TrackingLabels> = {
      san_FilterFoldoutType: filterId,
      san_FilterFoldoutActivity: open ? "open" : "close",
    };

    const hasPopularFacetValues: boolean | undefined = this.filter?.hasVisiblePopularValues;

    if (hasPopularFacetValues) {
      trackingData = { ...trackingData, ...{ san_PopularFacetValues: readSanPopularFacetValues() } };
    }

    const labels = readLegacyLabels(this.accordion);
    if (labels) {
      trackingData = { ...trackingData, ...labels };
    }

    tracker.submitEvent(trackingData);
  }

  /*                 */

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  private static handleAccordionToggle(event: Event) {
    const accordion = FilterAccordion.factory.closest(event.target);
    if (accordion) {
      accordion.trackAccordionToggle();
    }
  }
}
