/*                                                                                       */
import './hide-banner.css'
import { ConsentBannerVisibilityPort } from '../../ports'

export const HIDE_OT_CONSENT_BANNER_CLASS = 'eprivacy-ot-cookie-banner--hidden'

export class VisibilityAdapter implements ConsentBannerVisibilityPort {
  constructor(private readonly hideConsentBannerPaths: string[]) {}

  showConsentBanner() {
    window.document.body.classList.remove(HIDE_OT_CONSENT_BANNER_CLASS)
  }

  hideConsentBanner() {
    window.document.body.classList.add(HIDE_OT_CONSENT_BANNER_CLASS)
  }

  shouldShowConsentBanner() {
    const pathname = this.normalizePathname(window.location.pathname)

    return !this.hideConsentBannerPaths.some((path) => pathname.endsWith(path))
  }

  private normalizePathname(pathname: string) {
    return pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
  }
}
