<script lang="ts">
  import type { Snippet } from "svelte";
  import type { ClassValue } from "svelte/elements";

  interface Props {
    class?: ClassValue;
    /*                                                   */
    fitContent?: boolean;
    /*                                                                                                     */
    labelPlacement?: "left" | "right";
    input?: Snippet;
    children?: Snippet;
  }

  let { fitContent = true, labelPlacement = "right", input, children, ...rest }: Props = $props();

  let labelSize = $derived(fitContent ? "minmax(0, max-content)" : "1fr");
</script>

<label style="--label-width: {labelSize}" class={["hc_radio", `hc_radio--${labelPlacement}`, rest.class]}>
  {#if input}{@render input()}{:else}<input type="radio" />{/if}{@render children?.()}<span class="hc_radio__handle"
    ><span class="hc_radio__checkmark"></span></span
  ></label
>

<style lang="scss">
  .hc_radio {
    --input-color: var(--oc-semantic-color-canvas-background);
    --input-height: var(--oc-base-dimension-relative-24);
    --input-width: var(--oc-base-dimension-relative-24);
    --input-border-color: var(--oc-base-color-gray-200);
    --input-border-radius: 50%;
    --thumb-color: var(--oc-base-color-white);
    --thumb-size: var(--oc-base-dimension-relative-24);
    --checkmark-size: 10px;
    --checkmark-color-selected: var(--oc-semantic-color-text-default);

    align-items: center;
    gap: var(--oc-semantic-spacing-75);
    cursor: pointer;

    &:not([hidden]) {
      display: grid;
    }

    &--left {
      grid-template-columns: var(--label-width) [input-start] calc(var(--input-width) + 2px) [input-end];
      justify-items: start;
    }

    &--right {
      grid-template-columns: [input-start] calc(var(--input-width) + 2px) [input-end] var(--label-width);
      justify-items: end;
    }

    &__handle {
      grid-column: input-start / input-end;
      grid-row: 1;
      justify-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--thumb-size);
      width: var(--thumb-size);
      border-radius: var(--input-border-radius);
      outline: 1px solid var(--input-border-color);

      @at-root :global(input):not(:checked) ~ & {
        --checkmark-color: transparent;
      }

      @at-root :global(input):checked ~ & {
        --checkmark-color: var(--checkmark-color-selected);
      }
    }

    &__checkmark {
      border-radius: var(--input-border-radius);
      background-color: var(--checkmark-color);
      height: var(--checkmark-size);
      width: var(--checkmark-size);
    }

    :global(input) {
      appearance: none;
      cursor: pointer;
      margin: 0;
      justify-self: center;
      height: var(--input-height);
      width: var(--input-width);
      border-radius: var(--input-border-radius);
      grid-column: input-start / input-end;
      grid-row: 1;
      background-color: var(--input-color);

      &:focus-visible {
        outline: var(--oc-semantic-focus-outline-color, #008cf8) var(--oc-semantic-focus-outline-style, solid)
          var(--oc-semantic-focus-outline-width, 2px);
        outline-offset: var(--oc-semantic-focus-outline-offset, 2px);
      }
    }

    @media (hover: hover) {
      @at-root &:hover &__handle {
        outline-width: var(--oc-semantic-focus-outline-offset, 2px);
      }
    }
  }
</style>
