import { eventQBus } from "../types/EventQBus";
import tracking from "./Tracking";

let initialized = false;

function findTileList(): HTMLElement | null {
  return document.getElementById("reptile-search-result");
}

function addTilelistFeatureTracking(): void {
  const element: HTMLElement | null = findTileList();
  const dataset: DOMStringMap | undefined = element?.dataset;

  const { tsFeature } = (element && dataset) || {};

  if (tsFeature) {
    const tsFeatureData = JSON.parse(tsFeature);
    tracking.addFeaturesToPageImpression(tsFeatureData);
  }
}

export function registerTilelistTracking() {
  if (initialized) {
    return;
  }
  initialized = true;

  addTilelistFeatureTracking();
  eventQBus.on("ftfind.tilelist.loaded", addTilelistFeatureTracking);
}
