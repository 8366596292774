<script lang="ts">
  import {FindQaId, SqrlQaId, SqrlTestId} from "../../constants/squirrel";
  import {searchfieldStore} from "../../store/SearchfieldStore";
  import {activeIndex, indexStore} from "../../store/IndexStore";
  import {tracking} from "../../tracking/Tracking";
  import {SanHeader} from "../../events/sanHeader";
  import {COLOR} from "../../constants/color";

  const {value, isEmpty} = searchfieldStore;

  /**
 *
 *
 *
 *
 *
 *
 */
  function handleReset() {
    $activeIndex = -1;
    indexStore.reset();
    $value = "";
    const inputElement: HTMLInputElement | null = document.querySelector(".js_squirrel_searchbar__input");
    inputElement?.focus();
    tracking.trackSubmitEvent({san_Header: [SanHeader.X]});
  }

  let color = COLOR.GRAY_400;
  isEmpty.subscribe((value) => {
    if (value) {
      color = COLOR.GRAY_400;
    }
  });

</script>

{#if !$isEmpty}
    <oc-icon-button-v2 class="squirrel_delete-button js_squirrel_searchbar__delete"
                       data-qa-id={SqrlQaId.SEARCH_RESET}
                       data-qa={FindQaId.SEARCH_RESET_SVG}
                       on:click={handleReset}
                       on:mouseenter={() => {color=COLOR.RED_100}}
                       on:mouseleave={() => {color=COLOR.GRAY_400}}
                       size="50"
                       oc-aria-label="Suchbegriff löschen"
                       data-testid={SqrlTestId.SEARCH_RESET} icon="clear" variant="inverted-transparent"
                       icon-color={color}>
    </oc-icon-button-v2>
{/if}

