<script lang="ts">
  import type { Snippet } from "svelte";
  import type { ClassValue } from "svelte/elements";

  interface Props {
    class?: ClassValue;
    /*                                                   */
    fitContent?: boolean;
    /*                                                                                                     */
    labelPlacement?: "left" | "right";
    /*                                   */
    size?: "50" | "100";
    input?: Snippet;
    children?: Snippet;
  }

  let {
    fitContent = false,
    labelPlacement = "right",
    size = "100",
    input = defaultInput,
    children,
    ...rest
  }: Props = $props();

  let labelSize = $derived(fitContent ? "minmax(0, max-content)" : "1fr");
</script>

{#snippet defaultInput()}
  <input type="checkbox" />
{/snippet}

<label
  style="--label-width: {labelSize}"
  class={["hc_switch", `hc_switch--${size}`, `hc_switch--${labelPlacement}`, rest.class]}
  >{@render input()}{@render children?.()}<span class="hc_switch__handle"
    ><oc-icon-v1 type="check" size={"50"} color="var(--checkmark-color)" class="hc_switch__checkmark"
    ></oc-icon-v1></span
  ></label
>

<style lang="scss">
  .hc_switch {
    --track-color: var(--oc-base-color-gray-200);
    --track-color-selected: var(--oc-semantic-color-success-75);
    --track-height: var(--oc-base-dimension-relative-32);
    --track-width: var(--oc-base-dimension-relative-48);
    --track-border-radius: calc(var(--track-height) / 2);
    --thumb-color: var(--oc-base-color-white);
    --thumb-color-selected: var(--oc-semantic-color-success-75);
    --thumb-size: var(--oc-base-dimension-relative-24);
    --thumb-shift: calc((var(--track-width) - var(--track-height)) / 2);
    --checkmark-size: var(--oc-base-dimension-relative-16);
    --checkmark-color-selected: var(--oc-base-color-corporate-color-spectrum-positive-green-200);

    align-items: center;
    gap: var(--oc-semantic-spacing-100);
    cursor: pointer;

    &--50 {
      --track-height: var(--oc-base-dimension-relative-24);
      --track-width: 2.25rem;
      --thumb-size: var(--oc-base-dimension-relative-16);
      --checkmark-size: var(--oc-base-dimension-relative-12);
    }

    &:not([hidden]) {
      display: grid;
    }

    &--left {
      grid-template-columns: var(--label-width) [track-start] var(--track-width) [track-end];
      justify-items: start;
    }

    &--right {
      grid-template-columns: [track-start] var(--track-width) [track-end] var(--label-width);
      justify-items: end;
    }

    &__handle {
      grid-column: track-start / track-end;
      grid-row: 1;
      justify-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--thumb-size);
      width: var(--thumb-size);
      border-radius: 50%;
      background: var(--thumb-color);

      @media (prefers-reduced-motion: no-preference) {
        transition: transform 100ms ease-out;
      }

      @at-root :global(input):not(:checked) ~ & {
        transform: translateX(calc(-1 * var(--thumb-shift)));
        --checkmark-color: var(--thumb-color);
      }

      @at-root :global(input):checked ~ & {
        transform: translateX(var(--thumb-shift));
        --checkmark-color: var(--checkmark-color-selected);
      }
    }

    &__checkmark {
      height: var(--checkmark-size);
      width: var(--checkmark-size);
    }

    :global(input) {
      appearance: none;
      cursor: pointer;
      margin: 0;
      height: var(--track-height);
      width: var(--track-width);
      border-radius: var(--track-border-radius);
      grid-column: track-start / track-end;
      grid-row: 1;
      transition:
        background-color 100ms,
        opacity 0.4s;

      &:not(:checked) {
        background-color: var(--track-color);
      }

      &:checked {
        background-color: var(--track-color-selected);
      }

      &:focus-visible {
        outline: var(--oc-semantic-focus-outline-color, #008cf8) var(--oc-semantic-focus-outline-style, solid)
          var(--oc-semantic-focus-outline-width, 2px);
        outline-offset: var(--oc-semantic-focus-outline-offset, 2px);
      }
    }

    @media (hover: hover) {
      @at-root &:hover :global(input) {
        /*                                                                                                            */
        /*                      */

        &:not(:checked) {
          background-color: var(--oc-base-color-gray-400);
        }
        &:checked {
          background-color: #008176;
        }
      }
    }
  }
</style>
